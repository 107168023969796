<template>
  <div>
    <create-slider v-model="slider" title="Yangi slider"></create-slider>
    <div class="container">
      <a-button
        @click="createSlider"
        class="bg-success text-white ml-md-1 ml-lg-2"
      >
        Slider qo'shish
      </a-button>
    </div>

    <modal v-if="inValid" title="Xato" @close="closeModal">
      <p class="text-white bg-danger p-3 mt-3">
        Iltimos slider title, kategoriya, content va rasmni kiriting
      </p>
    </modal>
  </div>
</template>

<script>
import CreateSlider from '../../components/Slider/CreateSlider.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    CreateSlider,
  },
  data() {
    return {
      img: false,
      inValid: false,
      slider: {
        title: '',
        content: '',
        category: {
          id: '',
          name: '',
        },
        image: '',
      },
    };
  },

  methods: {
    ...mapActions({
      postSlider: 'slider/postSlider',
    }),
    closeModal(bool) {
      this.inValid = bool;
    },
    createSlider() {
      const obj = {
        title: this.slider.title,
        content: this.slider.content,
        category: this.slider.category,
        image: this.slider.image.files,
      };
      for (let i in obj) {
        if (obj[i] === '') {
          this.inValid = true;
          return;
        }
      }
      this.postSlider(obj)
        .then(() => {
          this.$notification['success']({
            message: "Muffaqiyatli qo'shildi",
            description: "Slider qo'shildi :)",
          });

          this.slider = {
            title: '',
            content: '',
            category: {
              id: '',
              name: '',
            },
            image: {},
          };
        })
        .catch((error) => {
          this.$notification['error']({
            message: 'Xatolik',
            description:
              "Serverga slider qo'shilmadi ): birozdan keyin urining" +
              error.message,
          });
        });
    },
  },
};
</script>
